.footer {
    margin: 248px 0 0 47px;
}

.text {
    color: #384558;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    padding: 8px 13px;
}