.header,
.navbar > ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    align-items: center;
}

.navbar > ul {
    display: flex;

    align-items: center;
    justify-content: space-between;
    list-style: none;
    /*padding: -1% 17% 0 0;*/
    align-items: center;
    width: 100%;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    border-bottom: 1px solid rgba(131, 131, 131, 0.21);
}

.navbar {
    margin-left: 12%;
}

.navbar > ul > a {

    text-decoration: none;
    color: #FFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 122px;
    text-align: center;
    margin: 0 3%;

}

.navbar > ul > a > li {

    margin: 5%;
    color: #FFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;


}

.logo {
    display: flex;
    align-items: center;
    margin: 22px 0 16px 40px;

}

.logo > img {
    width: 161.18px;
}

.manu {
    color: white;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    color: #FFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.tel {
    margin-right: 40px;
    display: flex;
    align-items: center;
    width: 271px;
    color: #FFF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.tel > p > a {
    margin-left: 9px;
    color: #FFFFFF;
}

.button {
    color: #fff;
    font-family: Inter;
    font-size: 15.3px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 50px;
    background: var(--2, linear-gradient(306deg, #0BB2F2 10.63%, #2E6BE2 83.88%));
    padding: 7px 15px;
    border: none;
    margin-left: 20px;
    animation: btn 8s linear 0s infinite alternate;
}

.navbar > ul > a > .eventColor {
    color: #384558;
    position: relative;
    z-index: 1110;
}

option {
    border: none;
}

select {
    border: none;
}

.tel > .eventColor {
    color: #384558;
}


/*//////////////////////////////////////////////////////////modal////////////////////////////////////////*/


.modalManu {
    position: absolute;
    list-style: none;
    margin-top: 23px;
    background-color: #384558;
    text-align: center;
    display: flex;
    justify-content: center;
    width: auto;
    height: auto;
}

.modalManu > a > li {
    position: relative;
    z-index: 1111;
    padding: 5px 5px;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    font-size: 14px;
    width: 150px;
}

.manu1_in {
    position: absolute;
    z-index: 1111;
    top: 100%;
    left: 0;
    list-style: none;
}

.manu1_in > a > li {
    background-color: rgba(56, 69, 88, 0.87);
    color: #fff;
    padding: 5px;
    font-size: 14px;
    box-shadow: inset -1px -1px 3px #fff;
    font-weight: 300;
    transition: 1s;
    width: 150px;
}

.manu1_in > a > li:hover {
    background-color: rgba(255, 255, 255, 0.92);
    box-shadow: inset 1px 1px 3px #384558;
    color: #384558;
}

.modalOpen {

    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.modLi {
    width: 153px;
}


.navbar > ul > a > li {

    margin: 5px;
}


@media only screen and (max-width: 1202px) {
    .tel {
        margin: 0;
        width: auto;
        padding: 0 0 0 0;
    }

    .tel > img,
    .tel > p {
        display: none;
    }

    .navbar > ul > a > li {
        font-size: 90%;
        margin: 5px;
    }

    .navbar {
        margin: 0;
    }
}


.burger {
    display: none;
}

.burger > div {
    width: 30px;
    height: 5px;
    margin: 5px;
    background-color: #ffff;
}


@media only screen and (max-width: 900px) {
    .burger {
        display: block;
    }

    .header > .logo {
        margin: 22px 0 16px 10px;
    }

    .navbarblocl {
        display: none;
    }


    /* //////////////////////////////////////////////   mobile style start max-width 900px*/
    .burger {
        cursor: pointer;
    }

    .modalBurger {
        position: absolute;
        top: 60px;
        left: 0;
        z-index: 20000000001;
        width: 100%;

    }

    .modalBurgerBack {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 2000000000;
        background-color: rgba(0, 0, 0, 0.91);
    }


    .navbar > ul {
        position: relative;
        z-index: 20000000001;
        width: 100%;
        text-align: center;
        display: block;
        transition: 1s;
        margin: 0;
        padding: 0;
    }

    .navbar > ul > a > li {
        position: relative;
        z-index: 20000000001;
        text-align: center;
        width: 80%;
        margin: 1% 10%;
        padding: 20px;
        /*height: 30px;*/
        box-shadow: inset -5px -5px 20px white;
    }

    .modalManu {
        position: unset;
        display: block;
    }

    .modalManu > a {
        width: 100%;
        height: 100%;
    }

    .modalManu > a > li {
        padding: 10px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #2E6BE2;

    }

    .manu1_in {
        position: unset;
        width: 100%;
        font-size: 14px;

    }
}


@keyframes btn {
    from {
        background: var(--2, linear-gradient(306deg, #0BB2F2 10.63%, #2E6BE2 83.88%));
    }
    to {
        background: var(--2, linear-gradient(306deg, #2E6BE2 10.63%, #0BB2F2 83.88%));
    }
}

.headers > .header > .tel > .blackcolor {
    color: black;
}