.block {
    position: relative;
}

.cards {
    margini-tpo: 6px;
    margin-left: 10.4166667%;
    margin-right: 10.4166667%;
    display: flex;
    justify-content: space-between;
}

.card {

    text-align: center;
    margin-right: -1px;
}

.ul {
    padding: 28px 21px;
    width: 353px;
    list-style: square inside url("./img/Frame5l.png");
    list-style-position: outside;
    height: 100%;
}

.card > img {

    margin: 3px 8px 67px 0;
}

h4 {
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    color: #384558;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ul > li {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 0px 0 0 10px;
    text-align: start;
    width: 103%;
    margin-bottom: 17px;
    margin-left: 2px;
}


.title {
    position: absolute;
    top: -155px;
    width: 100%;
    text-align: center;
    color: #384558;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;

    font-weight: 800;
    line-height: normal;
}

.ul1 > li {
    margin: 100px;

}

@media only screen and (max-width: 1330px) {
    .cards {
        margin: 0;
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 900px) {
    .cards {
        display: block;
    }

    .card {
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }

    .card > h4 {
        position: absolute;
        width: 80%;
        top: -20px;
        left: 2%;
    }
}

@media only screen and (max-width: 500px) {
    .ul1 {
        padding: 28px 0;
    }
}