.topimg1 {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 121px;
}

.block {
    position: relative;
}

.topimg2 {
    position: relative;
    z-index: 1;
    width: auto;
    top: -88px;
    left: 34.7%;
}

.cointainerblocks {
    margin: 0px 0 0 210px;
    margin-top: -11px;

}

.back {
    position: absolute;
    z-index: -1;
    top: -100px;
    width: 100%;
    height: 640px;
    background-image: url("./img/Rectangle 5 (1).png");
    background-size: cover;
    background-position: center center;
}


.topblock {
    text-align: center;
    padding: 0 17% 0 0;
}

.topblock > h2 {
    color: var(--colors-3, #384558);
    font-family: Inter;
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 50.4px */
}

.btns {
    display: flex;
    justify-content: center;
}

.topblock > .btns > button {
    border-radius: 35px;
    border: 1px solid var(--Grey, #8C97A6);
    display: flex;
    padding: 10px 15px;
    align-items: flex-start;
    gap: 10px;
    color: var(--Grey, #8C97A6);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin: 1.8% 0.5%;
}

.topblock > .btns > button:hover {
    background-color: #159DFF;
    color: #FFFFFF;
    border: 1px solid white;
}

.topblock > p {
    color: var(--colors-black, #384558);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 25.2px */
    padding: 17px 93px;
}


.bottomblock {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 215px 144px 0 0;
    overflow: hidden;
}

.card {
    display: flex;
    width: 50%;
    margin-bottom: 145px;
}

.cardText {
    margin-left: 30px;
}

.cardText > h3 {
    color: var(--colors-black, #384558);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-right: 47%;

}

.cardText > p {
    color: var(--colors-black, #384558);
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 73px 19px 0;
}

.cardText > a {
    border-radius: 30px;
    border: 1px solid var(--colors-black, #384558);
    background: #FFF;
    display: flex;
    padding: 10px 15px;
    align-items: flex-start;
    gap: 10px;
    color: var(--colors-black, #384558);
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 113px;
}

.cardText > a:hover {
    color: #2E6BE2;
    background-color: #00b7ff;

}


.foother {
    margin-top: 422px;
    padding: 0 0 107px 66px;
}

.foother > ul {
    display: flex;
}

.foother > ul > a {
    list-style: none;
    text-decoration: none;
    color: var(--colors-black, #384558);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 49.5px;
    position: relative;
}

.foother > ul > a > img {
    position: relative;
    left: 5px;
}

.foother > p {
    position: absolute;
    bottom: 36px;
    color: var(--colors-3, #384558);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}

.foother > p > span {
    color: var(--colors-3, #384558);
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    margin-left: 64px;
}

.footherimg1 {
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    left: 0;
}

.footherimg2 {
    z-index: -2;
    position: absolute;
    bottom: 128px;
    width: 60%;
    right: 0;
}


.topcards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 127px;
    margin-left: 3.5%;
}

.allcards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 75px;
    padding-left: 51px;
}

.bannercard {
    width: 644px;
    margin-right: 3.5%;
}

.bannercard > h2 {
    color: var(--colors-3, #384558);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 28.8px */
    margin: 12px 0 0 0;
}

.card > div,
.bannercard > div {
    display: flex;
    justify-content: space-between;
    margin: 14px 0 0 0px
}

.bannercard > div > p {
    color: var(--Grey, #8C97A6);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 15.6px */
    text-transform: uppercase;
}

.card {
    width: 419px;
    display: block;
    margin-right: 40px;
}

.card > div > p {
    color: var(--Grey, #8C97A6);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.card > h2 {
    color: var(--colors-3, #384558);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 21.6px */
    margin-top: 14px;
}

@media only screen and (max-width: 970px) {
    .cointainerblocks {
        margin: 0;
        padding: 0;
    }

    .topblock {
        padding: 0;
    }

    .bannercard {
        width: 100%;
        margin-left: 0;
    }

    .card {
        width: 320px;
        margin: 0;
    }

    .allcards {
        padding-left: 0;
        text-align: center;
        justify-content: center;
    }

    .card > img {
        width: 100%;
    }

    .bannercard > img {
        width: 100%;
    }
}