.topimage {
    width: 100%;
    height: 120px;
    position: absolute;
    top: 0;

}

.block {
    position: relative;
    overflow: hidden;
}