.block {

}

.thisblock {
    position: relative;
    height: 2250px;
}

all {
    position: relative;
    height: 1300px;
}

.bannerBlock {
    display: flex;
    justify-content: center;
}

.leftBlock {
    width: 50%;
    padding: 56px 0 0 66px;
}

.rightBlock {
    width: 50%;
    overflow: hidden;
    padding: 62px 0 0 26px
}

.links {
    display: flex;
    justify-content: space-between;
    width: 200px;
    align-items: center;
}

.links > a {
    text-decoration: none;
    color: #8C97A6;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
}

.leftBlock > h2 {
    color: var(--colors-3, #384558);
    font-family: Inter;
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 50.4px */
    margin-top: 28px;
}

.redLine {
    border-left: 2px solid #159DFF;
    padding: 0 36px 0 27px;
    margin-top: 30px;
}

.redLine > p {
    color: var(--colors-black, #384558);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 25.2px */
}


.contactBlock {
    display: flex;
    margin-top: 30px;
}

.contactBlock > div > h3 {
    color: var(--colors-black, #384558);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    padding: 0 0 0 20px;
}

.contactBlock > div > p {
    color: #9B9B9B;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 18.2px */
    padding: 0 250px 0 20px

}


.itamConTexts {
    margin: 0 21% 0 22%;
}

.itamConTexts > ul {
    padding-left: 20px;
}

.itamConTexts > h2 {
    margin-top: 100px;
    color: var(--colors-black, #384558);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
    margin-bottom: 20px;
}

.itamConTexts > ul > li,
.itamConTexts > p {
    color: var(--colors-black, #384558);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 22.4px */
    margin: 10px 0;
}

.itamConTexts > h3 {
    color: var(--colors-black, #384558);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 22.4px */
    margin-top: 20px;
}

.itamConTexts > ul > li {
    margin: 0;
}

.itamConTexts > img {
    margin-top: 100px;
}


.foother {
    width: 100%;
    position: relative;
    margin-top: 422px;
    padding: 0 0 107px 66px;
}

.foother > ul {
    display: flex;
    list-style: none;
}

.foother > ul > a {
    position: relative;
    z-index: 3;
    list-style: none;
    text-decoration: none;
    color: var(--colors-black, #384558);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 49.5px;
    position: relative;
}

.foother > ul > a > img {
    position: relative;
    z-index: 3;
    left: 5px;
}

.foother > p {
    position: absolute;
    z-index: 3;
    bottom: 36px;
    color: var(--colors-3, #384558);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}

.foother > p > span {
    color: var(--colors-3, #384558);
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    margin-left: 64px;
}

.footherimg1 {
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    left: 0;
}

.footherimg2 {
    z-index: 1;
    position: absolute;
    bottom: 128px;
    width: 60%;
    right: 0;
}


.thisblock {
    display: flex;
}

.burgerTools {
    background-color: #F6F8FB;
    position: relative;
    top: 0;
    left: 0;
    padding: 42px 30px 0 20px;

    height: 3000px;
    max-width: 100%;

}

.topper > img {
    margin-left: 20px;
    height: auto;
}

.topper {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 54px;
}

.burgerTools > img {
    margin: 10px;
    position: absolute;
    z-index: 50;
    right: 0px;
    top: 15px;
    left: 1px;
}

.itamTools {
    width: 100%;
}

.topper > select {
    display: flex;
    width: 193px;
    height: 40px;
    padding: 10px 10px 10px 14px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
}

.itamTools > p {
    color: var(--colors-black, #384558);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 21px */
    margin-left: 25px;
    margin: 10px 25px;
}

.itamTools > h5 {
    color: var(--colors-black, #384558);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 10px auto;
    width: 250px;
}

@media only screen and (max-width: 1050px) {
    .rightBlock {
        display: none;
    }

    .leftBlock {
        width: 100%;
    }

    .itamConTexts {
        margin: 0 3%;
        text-align: center;
    }
}
