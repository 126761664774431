.block {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    padding-right: 15%;
    height: 70px;
    border-bottom: 1px solid gray;
}

.header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    height: 70px;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    align-items: center;
    padding: 0 40px;
}

.logoblock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rightbar,
.rightbar > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav > a {
    padding-right: 47px;
    display: flex;
    padding: 0px 40px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 29px;
    flex-shrink: 0;
    flex-wrap: wrap;
    color: var(--colors-black, #384558);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.nav:last-child {
    padding: 0;
}

.logoblock > h2 {
    color: var(--colors-1, #159DFF);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

rightbar > img {
    margin-left: 7px;
}

.logoblock > h2 {
    margin-left: 1px;
}

@media only screen and (max-width: 1050px) {
    .rightbar > .nav {
        display: none;
    }
}