.block {
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 714px;
    max-height: 1000px;
    flex-shrink: 0;
    background-image: url("./img/i2.png");
    background-repeat: no-repeat;
    background-size: cover;
    animation: block 1s linear;
}

.black {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 714px;
    background: url("./img/i.png");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
}

.white {
    width: 100%;
    height: 59px;
    position: absolute;
    bottom: 0;
    z-index: 3;
    background-image: url("./img/i1.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.setka {
    margin-left: 32%;;
    position: absolute;
    top: 70px;
    z-index: 2;
    background-image: url("./img/setka.png");
    width: 100%;
    height: 714px;

}

.itam {
    position: relative;
    top: 75px;
    display: flex;
}

.itam:first-child {
    margin-top: 83px;
    margin-left: 14.4%;
}

.text {

    color: #FFF;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 28px;
    animation: text 15s;
}

.title {

    max-width: 467px;
    color: #FFFFFF;
    text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.75);
    font-family: Inter;
    font-size: 57px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 57px */
    text-transform: uppercase;
    animation: title 8s;
}

.btns {
    display: flex;
    align-items: center;
    margin-top: 92px;
}

.btnInitroOne {
    position: relative;
    z-index: 11;
    border-radius: 30px;
    background: #159DFF;
    height: 52px;
    padding: 5px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    color: #FFFFFF;
    transition: 1s;
}

.btnInitroTwo:hover,
.btnInitroOne:hover {
    opacity: 0.5;
    box-shadow: inset 0 0 10px white;
}

.btnInitroOne > span {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btnInitroTwo {
    position: relative;
    z-index: 11;
    border-radius: 30px;
    border: 1px solid var(--colors-white, #FFF);
    padding: 15px 25px;
    background: rgba(255, 255, 255, 0);
    color: #FFFFFF;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 23px;
    transition: 1s;
}

.statickJpg {
    margin-top: -61px;
    margin-left: 3px;
    min-width: 525.18px;
    animation-name: tex;
    animation-duration: 10s;
    animation: tex 25s linear 0s infinite;
}

@media only screen and (max-width: 500px) {
    .itam:first-child {
        margin-top: 83px;
        margin-left: 0%;
    }

    .title {
        font-size: 50px;
    }
}

/*@media only screen and (min-width: 1440px) {*/
/*    .itam{*/
/*        justify-content: space-between;*/
/*        padding-right: 15%;*/
/*    }*/
/*    .statickJpg{*/
/*        margin-top: -61px;*/
/*        margin-left: 3px;*/
/*       width: 150% ;*/

/*    }*/

/*}*/
@keyframes tex {
    from {
        rotate: 0deg
    }
    to {
        rotate: 360deg
    }
}

@keyframes block {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes title {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes text {
    from {
        opacity: 0;
    }
    to {
        opacity: 1
    }
}