.block {
    position: relative;
    z-index: 4;
    width: 100vw;
    min-height: 1200px;
    max-height: 1800px;
    max-width: 1440px;
    overflow: hidden;
}

.backgraundFone {
    position: absolute;
    z-index: 1;
    top: 43px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.layer1 {
    position: absolute;
    right: -1.52777778%;
    top: 149px;
    background-image: url("./img/Vector 15.png ");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    height: 155px;
    width: 56%;
}

.layer2 {
    background-image: url("./img/Rectangle 6.png");
    background-repeat: no-repeat;
    background-size: cover;

    position: absolute;
    z-index: 2;
    top: 175px;
    width: 100%;
    height: 907px;
}

.layer3 {
    background-image: url("./img/Vector 14.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 164px;
    width: 71.111111%;
    position: absolute;
    right: 4.86111111%;
    top: 944px;

}

.itam {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 362px;
    padding-left: 5px;
    z-index: 5;
    width: 100%;
}

.leftBlock {
    padding-right: -39px;
    margin-left: 16.45%;
    width: 30.208%;
    /*min-height: 350px;*/
}

.leftBlock > h2 {
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: #384558;
}

.leftBlock > ul {
    padding: 40px 0 41px 25px;
}

.leftBlock > ul > li {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: #384558;
}

.leftBlock > h3 {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #384558;
    border-left: 5px solid #159DFF;
    padding: 11px 0 11px 25px;


}

.rightBlock {
    margin-left: 38px;
    width: 50%;
    height: 606.4px;
    padding: 50px 0px 0 0;
    overflow: hidden;
}

.rightBlockItam {
    padding: 1% 0 1% 1%;
    background-color: #384558;
    height: 100%;
    width: 100%;
    overflow: hidden;
}


@media only screen and (max-width: 1400px) {
    .layer1 {
        width: 50%;
        left: 0;
    }

    .layer3 {
        width: 100%;
        right: 0;
    }
}


@media only screen and (max-width: 1060px) {
    .leftBlock {
        width: 100%;
    }

    .itam {
        width: 100%;
        overflow: revert;
    }

    .leftBlock {
        margin: auto;
    }
}

@media only screen and (max-width: 600px) {
    .itam {
        display: block;
    }

    .rightBlock {
        width: 100%;
        margin: 0;
        height: 300px;
        display: flex;
        justify-content: center;
    }

    .rightBlockItam {
        display: flex;
        justify-content: center;
        overflow: auto;
    }
}