.block {
    width: 419px;
    height: 600px;
    position: fixed;
    z-index: 1001;
    bottom: -530px;
    right: 150px;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    background-color: #FFFFFF;

    transition: 3s;
    transform: translate(0, -530px);
}

.header {
    padding: 13px 18px;
    width: 100%;
    height: 72.25px;
    background: var(--2, linear-gradient(306deg, #0BB2F2 10.63%, #2E6BE2 83.88%));
    display: flex;
}

.chatBlock {
    position: relative;
    width: 100%;
    height: 530px;
    /*background-image: url("./img/image 76.png");*/
    /*background-position: center center;*/
    /*background-size: cover;*/
}

.info {
    color: #FFFFFF;
    padding: 3px 0 0 18px;
}

.info > h3 {
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

}

.info > h4 {
    color: #FFFFFF;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

/*///////////////////////////////////////////////// Messige block*/
.message {
    height: 150px;
    border-top: 1px solid rgba(128, 128, 128, 0.58);
    position: absolute;
    bottom: 0;
    width: 100%;
}

.messageInput {
    border: none;
    height: 75px;
    font-size: 18px;
    width: 80%;
}

.newMessage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.sendMessige {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: none;
    font-size: 25px;
    font-weight: 900;
}

.newMessage > p {
    width: 100%;
    margin: 0 5% 0 30%;
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 15px;
}

.fullMssiges {
    height: 350px;
    overflow-y: scroll;
}

/* width */
.fullMssiges::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.fullMssiges::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;

}

/* Handle */
.fullMssiges::-webkit-scrollbar-thumb {
    background: rgba(96, 196, 250, 0.78);
    border-radius: 10px;
}

/*.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji.emoji*/
.emoji {
    bottom: 155px;
    left: 0;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
}

.emoji > p {
    display: flex;
    cursor: pointer;
}
