* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    max-width: 1440px;
    min-width: 320px;
    margin: 0 auto;
}

body {
    /*background-color: rgba(0, 0, 0, 0.49);*/
}

a {
    text-decoration: none;
}

.cont {
    overflow: hidden;
    background-color: #FFFFFF;
    box-shadow: 0 0 100px gray;
}

@media only screen and (min-width: 1480px) {
    .header {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .App {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: -100;
        padding: 0 10%;
        overflow: hidden;
        box-shadow: inset 0 0 100px gray;
        /*background-color: #8C97A6;*/
        background-size: cover;
    }
}

button,
a,
li {
    cursor: url("./components/events/eventsList/date_img/Pointer Copy.png"), pointer;
}