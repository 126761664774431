.block > h1 {
    color: #384558;
    leading-trim: both;
    text-edge: cap;
    /* 1440+/Headings 1 */
    font-family: Inter;
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    padding-top: 130px;
    text-align: center;
}

.ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.ul > a {
    text-decoration: none;
    color: #8C97A6;

}

.ul > a > li {
    margin: 19px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.ul:first-child {
    margin-left: 0;
}