.block {
    position: relative;
    z-index: 1;
    min-height: 895px;
    max-height: 1000px;
    overflow: hidden;
    text-align: center;
    padding-top: 97px;
    letter-spacing: 0.5px;
}

.backgraund {
    position: absolute;
    z-index: -1;
    top: -200px;
    background-image: url("./img/Rectangle 5 (1).png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    background-widht: 2275px;
    width: 100%;
    height: 100%;
}

.title {
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0px;
}

.text {

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 29.4px;
    padding: 40px 264px;
    color: #384558;

}

.line {
    display: flex;
    justify-content: center;

}

.textH2 {
    margin-top: 40px;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media only screen and (max-width: 900px) {
    .text {
        padding: 2% 10%;
    }
}

@media only screen and (max-width: 500px) {
    .text {
        padding: 1%;
    }
}